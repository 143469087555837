import React from 'react';
import { Input } from './ui/input';
import { Label } from './ui/label';
import toast from 'react-hot-toast';

interface NumberInputProps {
  label?: string;
  value: number | string;
  onChange: (value: number) => void;
  onBlur?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  helpText?: string;
}

export default function NumberInput({
  label,
  value,
  onChange,
  onBlur,
  min,
  max,
  step = 1,
  helpText,
}: NumberInputProps) {
  const [inputValue, setInputValue] = React.useState<string>(value.toString());

  React.useEffect(() => {
    setInputValue(value.toString());
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue); // Allow any input value

    // Only call onChange if the value is a valid number
    const numValue = Number(newValue);
    if (!isNaN(numValue)) {
      // Enforce min/max constraints on change
      if (typeof min === 'number' && numValue < min) {
        onChange(min);
        return;
      }
      if (typeof max === 'number' && numValue > max) {
        onChange(max);
        return;
      }
      onChange(numValue);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let finalValue = Number(e.target.value);

    // Handle empty or invalid input
    if (e.target.value === '' || isNaN(finalValue)) {
      finalValue = min || 1;
      setInputValue(finalValue.toString());
      toast.error(`Please enter a valid number${min ? ` (minimum ${min})` : ''}`);
    }

    // Apply min/max constraints
    if (typeof min === 'number' && finalValue < min) {
      finalValue = min;
      setInputValue(finalValue.toString());
      toast.error(`Minimum value is ${min}`);
    }
    if (typeof max === 'number' && finalValue > max) {
      finalValue = max;
      setInputValue(finalValue.toString());
      toast.error(`Maximum value is ${max}`);
    }

    // Update the input value and notify parent
    setInputValue(finalValue.toString());
    if (onBlur) {
      onBlur(finalValue);
    } else {
      onChange(finalValue);
    }
  };

  return (
    <div>
      {label && (
        <Label className="text-xs text-muted-foreground">{label}</Label>
      )}
      <Input
        type="number"
        min={min}
        max={max}
        step={step}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        className="mt-1"
      />
      {helpText && (
        <p className="text-xs text-muted-foreground mt-1">{helpText}</p>
      )}
    </div>
  );
}