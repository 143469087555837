import { useState, useEffect } from 'react';
import { X, ShoppingCart } from 'lucide-react';
import { useSticker } from '../context/StickerContext';
import { calculatePrice, formatPrice } from '../utils/pricing';
import { Button } from './ui/button';
import { Label } from './ui/label';
import NumberInput from './NumberInput';
import toast from 'react-hot-toast';
import { initiateCheckout, uploadDesign } from '../utils/checkout';
import { getCanvasDataURL } from '../utils/canvas/exportHandlers';
import { useAnalytics } from '../context/AnalyticsContext';
import { trackEvents, trackStickerDesign } from '../utils/analytics';
import { fabric } from 'fabric';
import { createClipPath } from '../utils/shapes';

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CheckoutModal({ isOpen, onClose }: CheckoutModalProps) {
  const { 
    size, 
    quantity, 
    updateQuantity,
    finish,
    updateFinish,
    canvasShape,
    designName,
  } = useSticker();
  const { analytics } = useAnalytics();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [customerDetails, setCustomerDetails] = useState({
    email: '',
    phone: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    phone: '',
  });

  // Calculate total price based on minimum quantity of 50
  const price = calculatePrice(size.width, size.height, Math.max(50, quantity));
  
  // Calculate price per sticker based on actual quantity
  const pricePerSticker = quantity > 0 ? price / Math.max(50, quantity) : 0;

  const validateForm = () => {
    const newErrors = {
      email: '',
      phone: '',
    };

    // Quantity validation
    if (quantity < 50) {
      toast.error('Minimum quantity is 50 stickers');
      updateQuantity(50);
      return false;
    }

    // Email validation
    if (!customerDetails.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(customerDetails.email)) {
      newErrors.email = 'Please enter a valid email';
    }

    // Phone validation (optional)
    if (customerDetails.phone && !/^\+?[\d\s-()]+$/.test(customerDetails.phone)) {
      newErrors.phone = 'Please enter a valid phone number';
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  useEffect(() => {
    if (quantity < 50) {
      updateQuantity(50);
    }
  }, []);

  const generatePreview = async () => {
    if (isGeneratingPreview) return;
    
    try {
      setIsGeneratingPreview(true);
      console.log('Starting preview generation...');
      
      const canvas = (window as any).fabricCanvas as fabric.Canvas;
      if (!canvas) {
        throw new Error('Canvas not found');
      }

      // Ensure the shape is applied before generating preview
      if (canvasShape && !canvas.clipPath) {
        const clipPath = createClipPath(canvasShape, canvas.width!, canvas.height!);
        if (clipPath) {
          canvas.clipPath = clipPath;
          canvas.renderAll();
        }
      }

      // Wait a frame to ensure canvas is rendered
      await new Promise(resolve => requestAnimationFrame(resolve));

      console.log('Generating canvas data URL...');
      const dataUrl = await getCanvasDataURL(canvas);
      console.log('Preview URL format check:', {
        startsWithDataImage: dataUrl.startsWith('data:image/'),
        length: dataUrl.length,
        prefix: dataUrl.substring(0, 30) + '...'
      });
      
      // Validate the data URL
      if (!dataUrl.startsWith('data:image/')) {
        throw new Error('Invalid preview image generated');
      }

      setPreviewUrl(dataUrl);
    } catch (error) {
      console.error('Failed to generate preview:', error);
      toast.error('Failed to generate preview. Please try again.');
      setPreviewUrl('');
    } finally {
      setIsGeneratingPreview(false);
    }
  };

  const handlePurchase = async () => {
    if (isProcessing) return;

    if (!validateForm()) {
      return;
    }

    try {
      setIsProcessing(true);
      console.log('Starting purchase process...');

      // Generate preview if not already generated
      if (!previewUrl) {
        console.log('No preview URL, generating...');
        await generatePreview();
      }

      // Verify we have a valid preview
      if (!previewUrl) {
        throw new Error('Failed to generate design preview');
      }

      console.log('Upload data format check:', {
        startsWithDataImage: previewUrl.startsWith('data:image/'),
        length: previewUrl.length,
        prefix: previewUrl.substring(0, 30) + '...'
      });

      // First upload the design to R2
      const finalDesignUrl = await uploadDesign({
        imageData: previewUrl,
        shape: canvasShape === 'square' ? 'rect' : canvasShape, // Normalize square to rect
        size: {
          width: size.width,
          height: size.height
        },
        title: designName || 'Custom Sticker',
        quantity
      });

      if (!finalDesignUrl) {
        throw new Error('Failed to upload design');
      }

      console.log('Design uploaded successfully, URL:', finalDesignUrl);

      trackStickerDesign(analytics, trackEvents.CHECKOUT_STARTED, {
        width: size.width,
        height: size.height,
        shape: canvasShape,
        finish,
        quantity,
        price
      });

      // Format shape name for display
      let displayShape = canvasShape;
      if (displayShape === 'square' || displayShape === 'rect' || displayShape === 'rectangle') {
        displayShape = 'Square/Rectangle';
      } else {
        displayShape = displayShape.charAt(0).toUpperCase() + displayShape.slice(1);
      }

      const checkoutItem = {
        name: designName || 'Custom Die Cut Sticker',
        price: price,
        quantity: quantity,
        size: `${size.width}" × ${size.height}"`,
        finish: finish,
        shape: displayShape,
        image_url: finalDesignUrl,
        customer_email: customerDetails.email,
        customer_phone: customerDetails.phone,
      };

      await initiateCheckout([checkoutItem]);
    } catch (err) {
      console.error('Checkout error:', err);
      toast.error('Failed to start checkout. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleClose = () => {
    setPreviewUrl('');
    onClose();
  };

  const handleQuantityChange = (value: number) => {
    const validQuantity = Math.max(50, value);
    updateQuantity(validQuantity);
    
    trackStickerDesign(analytics, trackEvents.QUANTITY_CHANGED, {
      width: size.width,
      height: size.height,
      shape: canvasShape,
      finish,
      quantity: validQuantity,
      price: calculatePrice(size.width, size.height, validQuantity)
    });
  };

  const handleQuantityBlur = (value: number) => {
    if (value < 50) {
      const validQuantity = 50;
      updateQuantity(validQuantity);
      toast.error('Minimum quantity is 50 stickers');
      trackStickerDesign(analytics, trackEvents.QUANTITY_CHANGED, {
        width: size.width,
        height: size.height,
        shape: canvasShape,
        finish,
        quantity: validQuantity,
        price: calculatePrice(size.width, size.height, validQuantity)
      });
    }
  };

  const handleFinishChange = (newFinish: 'matte' | 'glossy') => {
    updateFinish(newFinish);
    trackStickerDesign(analytics, trackEvents.FINISH_CHANGED, {
      width: size.width,
      height: size.height,
      shape: canvasShape,
      finish: newFinish,
      quantity,
      price
    });
  };

  useEffect(() => {
    if (isOpen) {
      generatePreview();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className={`
        fixed inset-0 z-50 flex items-center justify-center p-4
        ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}
        transition-opacity duration-300
      `}
    >
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal */}
      <div className="relative bg-background rounded-lg shadow-xl w-full max-w-lg max-h-[90vh] overflow-y-auto">
        {/* Close button */}
        <Button
          variant="ghost"
          size="icon"
          onClick={onClose}
          className="absolute right-4 top-4 z-10 hover:bg-accent/20 transition-colors duration-200"
        >
          <X className="h-6 w-6" />
        </Button>

        {/* Header */}
        <div className="p-6 border-b border-border/10">
          <div className="flex items-center space-x-2 mb-1">
            <ShoppingCart className="h-5 w-5 text-primary" />
            <h2 className="text-2xl font-semibold text-foreground">Checkout</h2>
          </div>
          <p className="text-muted-foreground">Complete your sticker order</p>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6">
          <h3 className="text-lg font-bold mb-2">Review Your Order</h3>

          <div className="flex flex-col md:flex-row gap-2 md:gap-4 min-h-0">
            {/* Preview Section - Smaller on mobile */}
            <div className="w-full md:w-1/2 flex flex-col">
              <h3 className="text-sm md:text-base font-semibold mb-1 md:mb-2">Design Preview</h3>
              <div className="flex flex-col items-center space-y-4">
                <div className="relative w-full max-w-[300px] bg-white rounded-lg shadow-sm overflow-hidden">
                  {isGeneratingPreview ? (
                    <div 
                      className="w-full flex items-center justify-center bg-gray-50"
                      style={{ 
                        aspectRatio: `${size.width} / ${size.height}`,
                        minHeight: '150px',
                        maxHeight: '300px'
                      }}
                    >
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                    </div>
                  ) : previewUrl ? (
                    <div 
                      className="relative w-full flex items-center justify-center bg-[#f8f8f8] rounded-lg"
                      style={{ 
                        aspectRatio: `${size.width} / ${size.height}`,
                        minHeight: '150px',
                        maxHeight: '300px',
                        padding: '1rem'
                      }}
                    >
                      <img
                        src={previewUrl}
                        alt="Design Preview"
                        className="w-full h-full object-contain"
                        onError={() => {
                          setPreviewUrl('');
                          toast.error('Failed to load preview. Please try again.');
                        }}
                      />
                    </div>
                  ) : (
                    <div 
                      className="w-full flex items-center justify-center bg-gray-50 text-gray-400"
                      style={{ 
                        aspectRatio: `${size.width} / ${size.height}`,
                        minHeight: '150px',
                        maxHeight: '300px'
                      }}
                    >
                      <button
                        onClick={generatePreview}
                        className="px-3 py-1.5 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                      >
                        Generate Preview
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <p className="mt-1 text-center text-xs md:text-sm text-gray-500">
                Preview of your printed sticker
              </p>
            </div>

            {/* Order Details Section - Compact on mobile */}
            <div className="w-full md:w-1/2 flex flex-col">
              <h3 className="text-sm md:text-base font-semibold mb-1 md:mb-2">Order Details</h3>
              <div className="space-y-2">
                <div>
                  <label className="text-xs md:text-sm font-medium text-gray-700">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    value={customerDetails.email}
                    onChange={(e) =>
                      setCustomerDetails({ ...customerDetails, email: e.target.value })
                    }
                    className={`w-full rounded-md border ${
                      errors.email ? 'border-red-500' : 'border-gray-300'
                    } px-3 py-1.5 text-sm md:text-base shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500`}
                    placeholder="your@email.com"
                  />
                  {errors.email && (
                    <p className="mt-0.5 text-xs md:text-sm text-red-500">{errors.email}</p>
                  )}
                </div>

                <div>
                  <label className="text-xs md:text-sm font-medium text-gray-700">
                    Phone Number (optional)
                  </label>
                  <input
                    type="tel"
                    value={customerDetails.phone}
                    onChange={(e) =>
                      setCustomerDetails({ ...customerDetails, phone: e.target.value })
                    }
                    className={`w-full rounded-md border ${
                      errors.phone ? 'border-red-500' : 'border-gray-300'
                    } px-3 py-1.5 text-sm md:text-base shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500`}
                    placeholder="(123) 456-7890"
                  />
                  {errors.phone && (
                    <p className="mt-0.5 text-xs md:text-sm text-red-500">{errors.phone}</p>
                  )}
                </div>

                <div className="space-y-1.5 md:space-y-2">
                  <div className="flex items-center justify-between text-sm md:text-base">
                    <span className="font-medium">Size:</span>
                    <span>{size.width}" × {size.height}"</span>
                  </div>
                  <div className="flex items-center justify-between text-sm md:text-base">
                    <span className="font-medium">Shape:</span>
                    <span>{canvasShape === 'rect' ? 'Square/Rectangle' : canvasShape.charAt(0).toUpperCase() + canvasShape.slice(1)}</span>
                  </div>
                  <div className="flex items-center justify-between text-sm md:text-base">
                    <span className="font-medium">Finish:</span>
                    <select
                      value={finish}
                      onChange={(e) => handleFinishChange(e.target.value as 'matte' | 'glossy')}
                      className="rounded-md border border-gray-300 px-2 py-1 text-sm md:text-base shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    >
                      <option value="matte">Matte</option>
                      <option value="glossy">Glossy</option>
                    </select>
                  </div>
                  <div className="flex items-center justify-between text-sm md:text-base">
                    <span className="font-medium">Quantity:</span>
                    <NumberInput
                      value={quantity}
                      onChange={(value) => {
                        handleQuantityChange(value);
                      }}
                      onBlur={(value) => {
                        handleQuantityBlur(value);
                      }}
                      min={50}
                      max={10000}
                      className="w-24 md:w-28"
                    />
                  </div>
                  <div className="flex items-center justify-between text-sm md:text-base">
                    <span className="font-medium">Price per sticker:</span>
                    <span>{formatPrice(pricePerSticker)}</span>
                  </div>
                  <div className="flex items-center justify-between text-sm md:text-base font-semibold">
                    <span>Total Price:</span>
                    <span>{formatPrice(price)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 md:mt-4 flex justify-end border-t pt-2 md:pt-4">
            <Button
              onClick={handlePurchase}
              disabled={isProcessing}
              className="w-full md:w-auto text-sm md:text-base py-2 md:py-2.5"
            >
              {isProcessing ? (
                <div className="flex items-center justify-center">
                  <div className="mr-2 h-4 w-4 md:h-5 md:w-5 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                  Processing...
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <ShoppingCart className="mr-2 h-4 w-4 md:h-5 md:w-5" />
                  Checkout ({formatPrice(price)})
                </div>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}