import { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { useSticker } from '../context/StickerContext';
import { useWindow } from '../hooks/useWindow';
import { ImageToolbar } from './ImageToolbar';
import { toast } from 'react-hot-toast';
import { createClipPath } from '../utils/shapes';
import { saveCurrentDesign, loadCurrentDesign } from '../utils/storage';
import { useCanvasState } from '../hooks/useCanvasState';

interface FabricCanvasProps {
  width: number;
  height: number;
}

export default function FabricCanvas({ width, height }: FabricCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const fabricRef = useRef<fabric.Canvas | null>(null);
  const stateRef = useRef<any>(null);
  const { backgroundColor, designName, size, quantity, finish, canvasShape, setCanvas } = useSticker();
  const [selectedObject, setSelectedObject] = useState<fabric.Object | null>(null);
  const { isMobile } = useWindow();
  const designIdRef = useRef<string>(crypto.randomUUID());
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { saveState, restoreState } = useCanvasState();

  // Initialize canvas with better performance settings
  useEffect(() => {
    if (!canvasRef.current || !wrapperRef.current || width === 0 || height === 0) return;

    let canvas: fabric.Canvas | null = null;

    try {
      // Store current state if canvas exists
      if (fabricRef.current) {
        stateRef.current = saveState(fabricRef.current);
        fabricRef.current.dispose();
        fabricRef.current = null;
      }

      // Set default control styles for all objects
      fabric.Object.prototype.set({
        borderColor: '#2196F3',
        cornerColor: '#2196F3',
        cornerStyle: 'circle',
        cornerSize: 8,
        transparentCorners: false,
        padding: 8,
      });

      // Customize control rendering
      fabric.Object.prototype.controls = {
        ...fabric.Object.prototype.controls,
        mtr: new fabric.Control({
          x: 0,
          y: -0.5,
          offsetY: -40,
          cursorStyle: 'pointer',
          actionHandler: fabric.controlsUtils.rotationWithSnapping,
          render: fabric.controlsUtils.renderCircleControl,
        }),
      };

      // Create new canvas instance
      canvas = new fabric.Canvas(canvasRef.current, {
        width,
        height,
        backgroundColor: backgroundColor || '#ffffff',
        preserveObjectStacking: true,
        selection: true,
        controlsAboveOverlay: true,
        renderOnAddRemove: true,
        enableRetinaScaling: true,
        allowTouchScrolling: true,
        stopContextMenu: true,
        selectable: true,
      });

      // Set initial dimensions
      canvas.setWidth(width);
      canvas.setHeight(height);

      // Ensure controls are always on top
      canvas.wrapperEl.style.zIndex = '30';
      canvas.upperCanvasEl.style.zIndex = '35';

      // Disable canvas dragging
      canvas.selection = true;
      canvas.skipTargetFind = false;
      canvas.allowTouchScrolling = true;

      // Prevent canvas panning/moving
      canvas.on('mouse:down', (opt) => {
        const evt = opt.e;
        if (evt.target === canvas.getElement()) {
          evt.preventDefault();
          evt.stopPropagation();
          return false;
        }
      });

      // Prevent touch dragging of canvas
      canvas.on('touch:drag', (opt) => {
        const evt = opt.e;
        if (!opt.target) {
          evt.preventDefault();
          evt.stopPropagation();
          return false;
        }
      });

      // Assign to global window so that CheckoutModal can access the canvas
      (window as any).fabricCanvas = canvas;

      // Restore previous state or load saved design
      if (stateRef.current) {
        restoreState(canvas, stateRef.current, width, height).then(() => {
          if (canvasShape && canvas) {
            updateCanvasShape(canvas, canvasShape);
          }
        });
      } else {
        const savedDesign = loadCurrentDesign();
        if (savedDesign?.canvasData) {
          const parsedData = JSON.parse(savedDesign.canvasData);
          restoreState(canvas, parsedData, width, height).then(() => {
            if (canvasShape && canvas) {
              updateCanvasShape(canvas, canvasShape);
            }
            designIdRef.current = savedDesign.id;
          });
        }
      }

      // Set up event handlers
      const handleSelection = (e: fabric.IEvent) => {
        const selected = e.target || canvas?.getActiveObject();
        console.log('Selection event:', selected); // Debug log
        setSelectedObject(selected || null);
      };

      const handleDeselection = () => {
        console.log('Deselection event'); // Debug log
        setSelectedObject(null);
      };

      const handleModification = () => {
        if (!canvas) return;
        const activeObject = canvas.getActiveObject();
        console.log('Modification event, active object:', activeObject); // Debug log
        
        saveCurrentDesign({
          id: designIdRef.current,
          designName,
          size,
          quantity,
          finish,
          backgroundColor: backgroundColor || '#ffffff',
          canvasShape,
          created: new Date().toISOString(),
          lastModified: new Date().toISOString()
        });
        
        if (activeObject) {
          setSelectedObject(activeObject);
        }
      };

      canvas.on({
        'selection:created': handleSelection,
        'selection:updated': handleSelection,
        'selection:cleared': handleDeselection,
        'object:modified': handleModification,
        'object:added': (e) => {
          handleModification();
          if (e.target) {
            canvas.setActiveObject(e.target);
            handleSelection(e);
          }
        },
        'object:removed': () => {
          handleModification();
          handleDeselection();
        },
        'mouse:up': (e) => {
          if (e.target) {
            handleSelection({ target: e.target } as fabric.IEvent);
          }
        }
      });

      // Save reference to canvas
      fabricRef.current = canvas;
      setCanvas(canvas);
    } catch (error) {
      console.error('Error initializing canvas:', error);
      if (canvas) {
        canvas.dispose();
      }
    }

    // Return cleanup function
    return () => {
      try {
        if (fabricRef.current) {
          stateRef.current = saveState(fabricRef.current);
          fabricRef.current.off();
          fabricRef.current.dispose();
          fabricRef.current = null;
          (window as any).fabricCanvas = null;
        }
      } catch (error) {
        console.error('Error cleaning up canvas:', error);
      }
    };
  }, [width, height]);

  // Update canvas dimensions when width/height changes
  useEffect(() => {
    const canvas = fabricRef.current;
    if (!canvas) return;

    try {
      // Store current objects and their states
      const objects = canvas.getObjects().map(obj => ({
        object: obj,
        originalWidth: obj.width,
        originalHeight: obj.height,
        originalLeft: obj.left,
        originalTop: obj.top,
        originalScaleX: obj.scaleX,
        originalScaleY: obj.scaleY
      }));

      const oldWidth = canvas.width || width;
      const oldHeight = canvas.height || height;

      // Clear the canvas before resizing
      canvas.clear();

      // Set new dimensions
      canvas.setWidth(width);
      canvas.setHeight(height);

      // Calculate scale factors
      const scaleX = width / oldWidth;
      const scaleY = height / oldHeight;

      // Restore objects with proper scaling
      objects.forEach(({ object, originalWidth, originalHeight, originalLeft, originalTop, originalScaleX, originalScaleY }) => {
        // Calculate new position maintaining relative position
        const newLeft = originalLeft! * scaleX;
        const newTop = originalTop! * scaleY;

        // Calculate new scale that maintains aspect ratio
        const scale = Math.min(scaleX, scaleY);
        const newScaleX = (originalScaleX || 1) * scale;
        const newScaleY = (originalScaleY || 1) * scale;

        // Update object properties
        object.set({
          left: newLeft,
          top: newTop,
          scaleX: newScaleX,
          scaleY: newScaleY
        });

        // If it's an image, ensure aspect ratio is maintained
        if (object.type === 'image') {
          const img = object as fabric.Image;
          const aspectRatio = (originalWidth || 1) / (originalHeight || 1);
          if (aspectRatio > 1) {
            // Wider than tall
            img.scaleToWidth(img.width! * newScaleX);
          } else {
            // Taller than wide
            img.scaleToHeight(img.height! * newScaleY);
          }
        }

        object.setCoords();
        canvas.add(object);
      });

      // Update background and shape
      canvas.setBackgroundColor(backgroundColor || '#ffffff', () => {
        if (canvasShape) {
          updateCanvasShape(canvas, canvasShape);
        }
        canvas.renderAll();
        saveCurrentDesign({
          id: designIdRef.current,
          designName,
          size,
          quantity,
          finish,
          backgroundColor: backgroundColor || '#ffffff',
          canvasShape,
          created: new Date().toISOString(),
          lastModified: new Date().toISOString()
        });
      });
    } catch (error) {
      console.error('Error updating canvas dimensions:', error);
    }
  }, [width, height]);

  // Handle image upload event
  useEffect(() => {
    const handleImageUpload = async (event: CustomEvent<{ imageUrl: string, fileType: string }>) => {
      const canvas = fabricRef.current;
      if (!canvas) return;

      const { imageUrl, fileType } = event.detail;
      console.log('Handling image upload:', { fileType });
      
      // Handle SVG files differently
      if (fileType === 'image/svg+xml') {
        try {
          // Convert data URL to SVG content
          const base64Content = imageUrl.split(',')[1];
          const svgString = atob(base64Content);
          console.log('Original SVG:', svgString);

          // Parse SVG to handle background color
          const parser = new DOMParser();
          const doc = parser.parseFromString(svgString, 'image/svg+xml');
          const svgElement = doc.querySelector('svg');

          if (!svgElement) {
            console.error('No SVG element found');
            toast.error('Invalid SVG file');
            return;
          }

          // Extract background color from fill attribute
          let backgroundColor = svgElement.getAttribute('fill');
          if (backgroundColor) {
            // Remove fill attribute as it will be applied to canvas background
            svgElement.removeAttribute('fill');
          }

          // Convert back to string without the fill attribute
          const processedSvg = new XMLSerializer().serializeToString(svgElement);
          
          // Load SVG into fabric
          fabric.loadSVGFromString(processedSvg, (objects, options) => {
            if (!objects || objects.length === 0) {
              console.error('No SVG objects loaded');
              toast.error('Invalid SVG file');
              return;
            }

            const svgObject = fabric.util.groupSVGElements(objects, options);
            
            // Scale SVG to fit canvas
            const scale = Math.min(
              (canvas.width! * 0.8) / svgObject.width!,
              (canvas.height! * 0.8) / svgObject.height!
            );

            svgObject.set({
              left: canvas.width! / 2,
              top: canvas.height! / 2,
              originX: 'center',
              originY: 'center',
              cornerStyle: 'circle',
              cornerColor: '#2196F3',
              cornerStrokeColor: '#2196F3',
              borderColor: '#2196F3',
              cornerSize: 12,
              padding: 10,
              transparentCorners: false,
              scaleX: scale,
              scaleY: scale
            });

            // Set canvas background color if it was present in the SVG
            if (backgroundColor) {
              canvas.setBackgroundColor(backgroundColor, () => {
                canvas.add(svgObject);
                canvas.setActiveObject(svgObject);
                canvas.renderAll();
                saveCurrentDesign({
                  id: designIdRef.current,
                  designName,
                  size,
                  quantity,
                  finish,
                  backgroundColor: backgroundColor || '#ffffff',
                  canvasShape,
                  created: new Date().toISOString(),
                  lastModified: new Date().toISOString()
                });
              });
            } else {
              canvas.add(svgObject);
              canvas.setActiveObject(svgObject);
              canvas.renderAll();
              saveCurrentDesign({
                id: designIdRef.current,
                designName,
                size,
                quantity,
                finish,
                backgroundColor: backgroundColor || '#ffffff',
                canvasShape,
                created: new Date().toISOString(),
                lastModified: new Date().toISOString()
              });
            }
          });
        } catch (error) {
          console.error('Error processing SVG:', error);
          toast.error('Failed to process SVG file');
        }
      } else {
        console.log('Processing regular image');
        // Handle other image types
        const imgElement = new Image();
        imgElement.crossOrigin = 'anonymous';

        imgElement.onload = () => {
          console.log('Image loaded successfully');
          const fabricImage = new fabric.Image(imgElement, {
            left: canvas.width! / 2,
            top: canvas.height! / 2,
            originX: 'center',
            originY: 'center',
            cornerStyle: 'circle',
            cornerColor: '#2196F3',
            cornerStrokeColor: '#2196F3',
            borderColor: '#2196F3',
            cornerSize: 12,
            padding: 10,
            transparentCorners: false,
          });

          // Scale image to fit canvas
          const scale = Math.min(
            (canvas.width! * 0.8) / fabricImage.width!,
            (canvas.height! * 0.8) / fabricImage.height!
          );

          fabricImage.scale(scale);

          canvas.add(fabricImage);
          canvas.setActiveObject(fabricImage);
          canvas.renderAll();
          saveCurrentDesign({
            id: designIdRef.current,
            designName,
            size,
            quantity,
            finish,
            backgroundColor: backgroundColor || '#ffffff',
            canvasShape,
            created: new Date().toISOString(),
            lastModified: new Date().toISOString()
          });
          console.log('Image added to canvas');
        };

        imgElement.onerror = (error) => {
          console.error('Error loading image:', error);
          toast.error('Failed to load image');
        };

        imgElement.src = imageUrl;
      }
    };

    window.addEventListener('addImage', handleImageUpload as EventListener);
    return () => {
      window.removeEventListener('addImage', handleImageUpload as EventListener);
    };
  }, []);

  // Handle object deletion
  const handleDelete = () => {
    const canvas = fabricRef.current;
    if (canvas && selectedObject) {
      canvas.remove(selectedObject);
      canvas.renderAll();
      setSelectedObject(null);
      saveCurrentDesign({
        id: designIdRef.current,
        designName,
        size,
        quantity,
        finish,
        backgroundColor: backgroundColor || '#ffffff',
        canvasShape,
        created: new Date().toISOString(),
        lastModified: new Date().toISOString()
      });
    }
  };

  // Handle object duplication
  const handleDuplicate = () => {
    const canvas = fabricRef.current;
    if (canvas && selectedObject) {
      selectedObject.clone((cloned: fabric.Object) => {
        cloned.set({
          left: (selectedObject.left || 0) + 20,
          top: (selectedObject.top || 0) + 20,
        });
        canvas.add(cloned);
        canvas.setActiveObject(cloned);
        canvas.renderAll();
        saveCurrentDesign({
          id: designIdRef.current,
          designName,
          size,
          quantity,
          finish,
          backgroundColor: backgroundColor || '#ffffff',
          canvasShape,
          created: new Date().toISOString(),
          lastModified: new Date().toISOString()
        });
      });
    }
  };

  // Handle object rotation
  const handleRotate = () => {
    const canvas = fabricRef.current;
    if (canvas && selectedObject) {
      const currentAngle = selectedObject.angle || 0;
      selectedObject.rotate((currentAngle + 90) % 360);
      canvas.renderAll();
      saveCurrentDesign({
        id: designIdRef.current,
        designName,
        size,
        quantity,
        finish,
        backgroundColor: backgroundColor || '#ffffff',
        canvasShape,
        created: new Date().toISOString(),
        lastModified: new Date().toISOString()
      });
    }
  };

  // Handle centering object
  const handleCenter = () => {
    const canvas = fabricRef.current;
    if (canvas && selectedObject) {
      const center = canvas.getCenter();
      selectedObject.set({
        left: center.left,
        top: center.top,
        originX: 'center',
        originY: 'center',
      });
      canvas.renderAll();
      saveCurrentDesign({
        id: designIdRef.current,
        designName,
        size,
        quantity,
        finish,
        backgroundColor: backgroundColor || '#ffffff',
        canvasShape,
        created: new Date().toISOString(),
        lastModified: new Date().toISOString()
      });
    }
  };

  // Handle centering object vertically
  const handleCenterVertically = () => {
    const canvas = fabricRef.current;
    if (canvas && selectedObject) {
      const center = canvas.getCenter();
      selectedObject.set({
        top: center.top,
        originY: 'center'
      });
      canvas.renderAll();
      saveCurrentDesign({
        id: designIdRef.current,
        designName,
        size,
        quantity,
        finish,
        backgroundColor: backgroundColor || '#ffffff',
        canvasShape,
        created: new Date().toISOString(),
        lastModified: new Date().toISOString()
      });
    }
  };

  // Handle centering object horizontally
  const handleCenterHorizontally = () => {
    const canvas = fabricRef.current;
    if (canvas && selectedObject) {
      const center = canvas.getCenter();
      selectedObject.set({
        left: center.left,
        originX: 'center'
      });
      canvas.renderAll();
      saveCurrentDesign({
        id: designIdRef.current,
        designName,
        size,
        quantity,
        finish,
        backgroundColor: backgroundColor || '#ffffff',
        canvasShape,
        created: new Date().toISOString(),
        lastModified: new Date().toISOString()
      });
    }
  };

  // Handle shape changes
  useEffect(() => {
    const handleShapeChange = (event: CustomEvent<{ shape: string }>) => {
      const canvas = fabricRef.current;
      if (!canvas) return;

      const clipPath = createClipPath(event.detail.shape, canvas.width!, canvas.height!);
      if (clipPath) {
        canvas.clipPath = clipPath;
        canvas.renderAll();
        saveCurrentDesign({
          id: designIdRef.current,
          designName,
          size,
          quantity,
          finish,
          backgroundColor: backgroundColor || '#ffffff',
          canvasShape: event.detail.shape,
          created: new Date().toISOString(),
          lastModified: new Date().toISOString()
        });
      }
    };

    window.addEventListener('shapeChanged', handleShapeChange as EventListener);
    return () => {
      window.removeEventListener('shapeChanged', handleShapeChange as EventListener);
    };
  }, []);

  // Apply initial shape
  useEffect(() => {
    const canvas = fabricRef.current;
    if (!canvas) return;

    const clipPath = createClipPath(canvasShape, canvas.width!, canvas.height!);
    if (clipPath) {
      canvas.clipPath = clipPath;
      canvas.renderAll();
    }
  }, [canvasShape]);

  // Update background color
  useEffect(() => {
    const canvas = fabricRef.current;
    if (canvas) {
      canvas.setBackgroundColor(backgroundColor, () => {
        canvas.renderAll();
        saveCurrentDesign({
          id: designIdRef.current,
          designName,
          size,
          quantity,
          finish,
          backgroundColor: backgroundColor || '#ffffff',
          canvasShape,
          created: new Date().toISOString(),
          lastModified: new Date().toISOString()
        });
      });
    }
  }, [backgroundColor]);

  // Handle keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (selectedObject && (e.key === 'Delete' || e.key === 'Backspace')) {
        handleDelete();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedObject]);

  // Handle reset canvas event
  useEffect(() => {
    const handleResetCanvas = () => {
      const canvas = fabricRef.current;
      if (!canvas) return;

      // Clear all objects
      canvas.clear();
      
      // Reset background color to white
      canvas.setBackgroundColor('#ffffff', () => {
        canvas.renderAll();
        saveCurrentDesign({
          id: designIdRef.current,
          designName,
          size,
          quantity,
          finish,
          backgroundColor: '#ffffff',
          canvasShape,
          created: new Date().toISOString(),
          lastModified: new Date().toISOString()
        });
      });
    };

    window.addEventListener('resetCanvas', handleResetCanvas);
    return () => {
      window.removeEventListener('resetCanvas', handleResetCanvas);
    };
  }, []);

  const updateCanvasShape = (canvas: fabric.Canvas, shape: string) => {
    const clipPath = createClipPath(shape, canvas.width!, canvas.height!);
    if (clipPath) {
      canvas.clipPath = clipPath;
    }
  };

  return (
    <div ref={wrapperRef} className="relative w-full h-full" style={{ backgroundColor: 'var(--color-background)', transition: 'background-color 0.3s ease-in-out' }}>
      <canvas ref={canvasRef} className="absolute inset-0" />
      {selectedObject && (
        <ImageToolbar
          object={selectedObject}
          onDelete={() => {
            if (!fabricRef.current || !selectedObject) return;
            fabricRef.current.remove(selectedObject);
            fabricRef.current.renderAll();
            setSelectedObject(null);
          }}
          onDuplicate={() => {
            if (!selectedObject || !fabricRef.current) return;
            selectedObject.clone((cloned: fabric.Object) => {
              cloned.set({
                left: selectedObject.left! + 20,
                top: selectedObject.top! + 20,
                evented: true,
              });
              fabricRef.current?.add(cloned);
              fabricRef.current?.setActiveObject(cloned);
              setSelectedObject(cloned);
              saveCurrentDesign({
                id: designIdRef.current,
                designName,
                size,
                quantity,
                finish,
                backgroundColor: backgroundColor || '#ffffff',
                canvasShape,
                created: new Date().toISOString(),
                lastModified: new Date().toISOString()
              });
            });
          }}
          onRotate={() => {
            if (!selectedObject || !fabricRef.current) return;
            selectedObject.rotate((selectedObject.angle || 0) + 90);
            fabricRef.current.renderAll();
            saveCurrentDesign({
              id: designIdRef.current,
              designName,
              size,
              quantity,
              finish,
              backgroundColor: backgroundColor || '#ffffff',
              canvasShape,
              created: new Date().toISOString(),
              lastModified: new Date().toISOString()
            });
          }}
          onCenterVertically={() => {
            if (!selectedObject || !fabricRef.current) return;
            const canvas = fabricRef.current;
            selectedObject.centerV();
            canvas.renderAll();
            saveCurrentDesign({
              id: designIdRef.current,
              designName,
              size,
              quantity,
              finish,
              backgroundColor: backgroundColor || '#ffffff',
              canvasShape,
              created: new Date().toISOString(),
              lastModified: new Date().toISOString()
            });
          }}
          onCenterHorizontally={() => {
            if (!selectedObject || !fabricRef.current) return;
            const canvas = fabricRef.current;
            selectedObject.centerH();
            canvas.renderAll();
            saveCurrentDesign({
              id: designIdRef.current,
              designName,
              size,
              quantity,
              finish,
              backgroundColor: backgroundColor || '#ffffff',
              canvasShape,
              created: new Date().toISOString(),
              lastModified: new Date().toISOString()
            });
          }}
        />
      )}
    </div>
  );
}