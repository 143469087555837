import { fabric } from 'fabric';
import { 
  Copy, 
  Trash2, 
  RotateCw, 
  GripHorizontal,
  AlignVerticalJustifyCenter,
  AlignHorizontalJustifyCenter
} from 'lucide-react';
import { useWindow } from '../hooks/useWindow';
import { useEffect, useState } from 'react';
import { useCheckout } from '../context/CheckoutContext';
import { createPortal } from 'react-dom';
import { useSticker } from '../context/StickerContext';

interface Position {
  x: number;
  y: number;
}

interface ImageToolbarProps {
  object: fabric.Object;
  onDelete?: () => void;
  onDuplicate?: () => void;
  onRotate?: () => void;
  onCenterVertically?: () => void;
  onCenterHorizontally?: () => void;
}

export function ImageToolbar({
  object,
  onDelete,
  onDuplicate,
  onRotate,
  onCenterVertically,
  onCenterHorizontally
}: ImageToolbarProps) {
  const { isMobile } = useWindow();
  const { isCheckoutOpen } = useCheckout();
  const { canvas } = useSticker();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleCenterVertically = () => {
    if (!canvas || !object) return;
    
    // Get canvas center and object dimensions
    const canvasHeight = canvas.getHeight();
    const objectHeight = object.getScaledHeight();
    
    // Calculate center position, accounting for object's center point
    const centerY = canvasHeight / 2;
    const newTop = centerY - (objectHeight / 2);
    
    // Update object position
    object.set({
      top: newTop,
      left: object.left // Maintain horizontal position
    });
    
    canvas.renderAll();
    if (onCenterVertically) onCenterVertically();
  };

  const handleCenterHorizontally = () => {
    if (!canvas || !object) return;
    
    // Get canvas center and object dimensions
    const canvasWidth = canvas.getWidth();
    const objectWidth = object.getScaledWidth();
    
    // Calculate center position, accounting for object's center point
    const centerX = canvasWidth / 2;
    const newLeft = centerX - (objectWidth / 2);
    
    // Update object position
    object.set({
      left: newLeft,
      top: object.top // Maintain vertical position
    });
    
    canvas.renderAll();
    if (onCenterHorizontally) onCenterHorizontally();
  };

  // Listen for sidebar open/close state changes
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target instanceof HTMLElement) {
          const isOpen = !mutation.target.classList.contains('-translate-x-full');
          setIsSidebarOpen(isOpen);
        }
      });
    });

    const sidebar = document.querySelector('aside');
    if (sidebar) {
      observer.observe(sidebar, {
        attributes: true,
        attributeFilter: ['class']
      });
    }

    return () => observer.disconnect();
  }, []);

  const [position, setPosition] = useState(() => {
    const savedPosition = localStorage.getItem('toolbarPosition');
    if (savedPosition) {
      return JSON.parse(savedPosition);
    }
    // Default position: center horizontally, 20% from top
    return { 
      x: (window.innerWidth - (isMobile ? 280 : 320)) / 2, 
      y: window.innerHeight * 0.2 
    };
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState<Position>({ x: 0, y: 0 });

  // Update position when window resizes to keep toolbar in view
  useEffect(() => {
    const handleResize = () => {
      setPosition(prev => {
        const toolbarWidth = isMobile ? 280 : 320;
        const toolbarHeight = 48;
        
        let x = prev.x;
        let y = prev.y;

        // Keep toolbar within bounds
        if (x + toolbarWidth > window.innerWidth) {
          x = window.innerWidth - toolbarWidth;
        }
        if (y + toolbarHeight > window.innerHeight) {
          y = window.innerHeight - toolbarHeight;
        }
        
        return { 
          x: Math.max(0, x), 
          y: Math.max(0, y)
        };
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
    
    let clientX: number;
    let clientY: number;
    
    if ('touches' in e) {
      const touch = e.touches[0];
      clientX = touch.clientX;
      clientY = touch.clientY;
    } else {
      clientX = (e as React.MouseEvent).clientX;
      clientY = (e as React.MouseEvent).clientY;
    }
    
    setDragStart({
      x: clientX - position.x,
      y: clientY - position.y
    });

    // Prevent any scrolling during drag
    document.body.style.overflow = 'hidden';
  };

  const handleDrag = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    e.stopPropagation();
    
    let clientX: number;
    let clientY: number;
    
    if ('touches' in e) {
      const touch = e.touches[0];
      clientX = touch.clientX;
      clientY = touch.clientY;
    } else {
      clientX = (e as React.MouseEvent).clientX;
      clientY = (e as React.MouseEvent).clientY;
    }

    const newX = clientX - dragStart.x;
    const newY = clientY - dragStart.y;

    // Get toolbar dimensions
    const toolbarWidth = isMobile ? 280 : 320;
    const toolbarHeight = 48;
    
    // Keep toolbar within viewport bounds with a small margin
    const margin = 4;
    const boundedX = Math.max(
      margin, 
      Math.min(window.innerWidth - toolbarWidth - margin, newX)
    );
    const boundedY = Math.max(
      margin, 
      Math.min(window.innerHeight - toolbarHeight - margin, newY)
    );

    setPosition({ x: boundedX, y: boundedY });

    // Save position to localStorage
    localStorage.setItem('toolbarPosition', JSON.stringify({ x: boundedX, y: boundedY }));
  };

  const handleDragEnd = (e?: React.MouseEvent | React.TouchEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsDragging(false);
    document.body.style.overflow = '';
  };

  // Add global event listeners for drag
  useEffect(() => {
    if (isDragging) {
      const handleGlobalMouseMove = (e: MouseEvent) => {
        handleDrag(e as unknown as React.MouseEvent);
      };
      
      const handleGlobalTouchMove = (e: TouchEvent) => {
        e.preventDefault();
        handleDrag(e as unknown as React.TouchEvent);
      };
      
      const handleGlobalEnd = () => {
        handleDragEnd();
      };

      window.addEventListener('mousemove', handleGlobalMouseMove);
      window.addEventListener('touchmove', handleGlobalTouchMove, { passive: false });
      window.addEventListener('mouseup', handleGlobalEnd);
      window.addEventListener('touchend', handleGlobalEnd);
      window.addEventListener('touchcancel', handleGlobalEnd);

      return () => {
        window.removeEventListener('mousemove', handleGlobalMouseMove);
        window.removeEventListener('touchmove', handleGlobalTouchMove);
        window.removeEventListener('mouseup', handleGlobalEnd);
        window.removeEventListener('touchend', handleGlobalEnd);
        window.removeEventListener('touchcancel', handleGlobalEnd);
      };
    }
  }, [isDragging, dragStart.x, dragStart.y]);

  if (!object || isCheckoutOpen || (isMobile && isSidebarOpen)) return null;

  return createPortal(
    <div
      className={`fixed z-50 select-none
        ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}
        ${isCheckoutOpen ? 'pointer-events-none opacity-0' : 'opacity-100'}
        transition-opacity duration-200
      `}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        transform: 'translate3d(0, 0, 0)', // Force GPU acceleration
      }}
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
    >
      <div className={`flex items-center gap-1 p-2 rounded-lg shadow-lg
        bg-background/95 backdrop-blur-sm border border-border/50
        ${isSidebarOpen ? 'border-accent/20' : ''}
      `}>
        <button
          onClick={handleCenterVertically}
          className="p-2 rounded-md hover:bg-accent/20 transition-colors"
          title="Center Vertically"
        >
          <AlignVerticalJustifyCenter className="w-4 h-4" />
        </button>
        <button
          onClick={handleCenterHorizontally}
          className="p-2 rounded-md hover:bg-accent/20 transition-colors"
          title="Center Horizontally"
        >
          <AlignHorizontalJustifyCenter className="w-4 h-4" />
        </button>
        <div className="w-px h-6 bg-border/50 mx-1" />
        <button
          onClick={onDelete}
          className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
          title="Delete"
        >
          <Trash2 className="w-5 h-5" />
        </button>
        <button
          onClick={onDuplicate}
          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          title="Duplicate"
        >
          <Copy className="w-5 h-5 text-gray-600" />
        </button>
        <button
          onClick={onRotate}
          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          title="Rotate 90°"
        >
          <RotateCw className="w-5 h-5 text-gray-600" />
        </button>
        <div
          className="p-2 hover:bg-gray-100 rounded-lg cursor-grab active:cursor-grabbing"
          title="Drag to move toolbar"
        >
          <GripHorizontal className="w-5 h-5 text-gray-400" />
        </div>
      </div>
    </div>,
    document.body
  );
}