// Create a persistent shape cache
const shapeCache = new Map();

export function createClipPath(shape: string, width: number, height: number): fabric.Object | null {
  // Normalize shape name
  const normalizedShape = shape.toLowerCase();
  if (normalizedShape === 'square' || normalizedShape === 'rectangle') {
    shape = 'rect';
  }

  const cacheKey = `${shape}-${width}-${height}`;
  
  if (shapeCache.has(cacheKey)) {
    return shapeCache.get(cacheKey);
  }

  const center = { x: width / 2, y: height / 2 };

  let clipPath: fabric.Object;

  switch (shape) {
    case 'circle': {
      // For non-square dimensions, use the smaller dimension to create the circle
      const diameter = Math.min(width, height);
      const radius = diameter / 2;
      clipPath = new fabric.Circle({
        radius,
        left: center.x,
        top: center.y,
        originX: 'center',
        originY: 'center',
      });
      break;
    }

    case 'rect': 
      clipPath = new fabric.Rect({
        width,
        height,
        left: center.x,
        top: center.y,
        originX: 'center',
        originY: 'center',
      });
      break;

    case 'triangle': {
      // Calculate triangle dimensions to fit the container while maintaining proportions
      const triangleBase = width;
      const triangleHeight = height;
      
      // Create points for an isosceles triangle that fits the dimensions
      const points = [
        { x: -triangleBase/2, y: triangleHeight/2 },  // Bottom left
        { x: 0, y: -triangleHeight/2 },               // Top center
        { x: triangleBase/2, y: triangleHeight/2 },   // Bottom right
      ];
      
      clipPath = new fabric.Polygon(points, {
        left: center.x,
        top: center.y,
        originX: 'center',
        originY: 'center',
      });
      break;
    }

    case 'hexagon': {
      // Calculate hexagon dimensions to fit the container while maintaining proportions
      const hexWidth = width;
      const hexHeight = height;
      const points = [
        { x: hexWidth/2, y: 0 },                           // Right
        { x: hexWidth/4, y: -hexHeight/2 },               // Top right
        { x: -hexWidth/4, y: -hexHeight/2 },              // Top left
        { x: -hexWidth/2, y: 0 },                         // Left
        { x: -hexWidth/4, y: hexHeight/2 },               // Bottom left
        { x: hexWidth/4, y: hexHeight/2 }                 // Bottom right
      ];
      
      clipPath = new fabric.Polygon(points, {
        left: center.x,
        top: center.y,
        originX: 'center',
        originY: 'center',
        type: 'polygon'
      });
      break;
    }

    default:
      console.warn(`Unknown shape type: ${shape}, defaulting to rectangle`);
      clipPath = new fabric.Rect({
        width,
        height,
        left: center.x,
        top: center.y,
        originX: 'center',
        originY: 'center',
      });
      break;
  }

  shapeCache.set(cacheKey, clipPath);
  return clipPath;
}

export function applyShapeToDataURL(canvas: fabric.Canvas): string {
  if (!canvas.clipPath) return canvas.toDataURL();

  // Create a temporary canvas for the shaped version
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = canvas.width!;
  tempCanvas.height = canvas.height!;
  const ctx = tempCanvas.getContext('2d')!;

  // Draw the original canvas
  const dataUrl = canvas.toDataURL();
  const img = new Image();
  img.src = dataUrl;

  // Apply the clip path
  ctx.save();
  const path = new Path2D();
  
  if (canvas.clipPath instanceof fabric.Circle) {
    path.arc(
      canvas.clipPath.left! + canvas.clipPath.radius!,
      canvas.clipPath.top! + canvas.clipPath.radius!,
      canvas.clipPath.radius!,
      0,
      2 * Math.PI
    );
  } else if (canvas.clipPath instanceof fabric.Rect) {
    path.rect(
      canvas.clipPath.left!,
      canvas.clipPath.top!,
      canvas.clipPath.width!,
      canvas.clipPath.height!
    );
  } else if (canvas.clipPath instanceof fabric.Triangle) {
    const height = canvas.clipPath.height!;
    const width = canvas.clipPath.width!;
    const left = canvas.clipPath.left!;
    const top = canvas.clipPath.top!;
    
    path.moveTo(left, top + height);
    path.lineTo(left + width / 2, top);
    path.lineTo(left + width, top + height);
    path.closePath();
  } else if (canvas.clipPath instanceof fabric.Polygon) {
    const points = canvas.clipPath.points!;
    if (points.length > 0) {
      path.moveTo(points[0].x, points[0].y);
      for (let i = 1; i < points.length; i++) {
        path.lineTo(points[i].x, points[i].y);
      }
      path.closePath();
    }
  }

  ctx.clip(path);
  ctx.drawImage(img, 0, 0);
  ctx.restore();

  return tempCanvas.toDataURL();
}