import React, { useState, useRef, useEffect } from 'react';
import { useSticker } from '../context/StickerContext';

interface EditableTitleProps {
  className?: string;
}

export default function EditableTitle({ className = '' }: EditableTitleProps) {
  const { designName, setDesignName } = useSticker();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(designName);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  // Keep local state in sync with context
  useEffect(() => {
    setEditedName(designName);
  }, [designName]);

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (editedName.trim() === '') {
      setEditedName(designName);
      return;
    }
    if (editedName !== designName) {
      setDesignName(editedName);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      inputRef.current?.blur();
    } else if (e.key === 'Escape') {
      setEditedName(designName);
      setIsEditing(false);
    }
  };

  return (
    <div 
      className={`inline-flex items-center justify-center whitespace-nowrap ${className}`}
      onClick={handleClick}
    >
      {isEditing ? (
        <input
          ref={inputRef}
          type="text"
          value={editedName}
          onChange={(e) => setEditedName(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="bg-transparent border-none text-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-primary/20 rounded px-2 -mx-2 my-auto"
          style={{ minWidth: '100px', maxWidth: '300px', lineHeight: '1' }}
        />
      ) : (
        <h1 
          className="text-sm font-medium text-center cursor-pointer hover:text-primary/80 transition-colors my-auto leading-none p-0"
          title="Click to edit"
          style={{ lineHeight: '1' }}
        >
          {designName}
        </h1>
      )}
    </div>
  );
}
