import { useRef, useState, useEffect } from 'react';
import { useSticker } from '../context/StickerContext';
import { useAnalytics } from '../context/AnalyticsContext';
import { trackEvents, trackStickerDesign } from '../utils/analytics';
import Ruler from './Ruler';
import FabricCanvas from './FabricCanvas';
import cn from 'classnames';

export default function Canvas() {
  const { size, quantity, finish, canvasShape } = useSticker();
  const { analytics } = useAnalytics();
  const containerRef = useRef<HTMLDivElement>(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [lastModified, setLastModified] = useState(Date.now());
  const isMobile = window.innerWidth < 768;

  // Scale factor to convert inches to pixels (96 DPI)
  const SCALE_FACTOR = 96;

  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        
        // Calculate the scaled dimensions
        const scaledWidth = size.width * SCALE_FACTOR;
        const scaledHeight = size.height * SCALE_FACTOR;
        
        // Adjust padding based on screen size
        // Use more space on mobile (90%) vs desktop (80%)
        const scaleFactor = isMobile ? 0.9 : 0.8;
        
        // Calculate scaling factor to fit within container while maintaining aspect ratio
        const scale = Math.min(
          (containerWidth * scaleFactor) / scaledWidth,
          (containerHeight * scaleFactor) / scaledHeight
        );
        
        setCanvasSize({
          width: Math.floor(scaledWidth * scale),
          height: Math.floor(scaledHeight * scale)
        });
      }
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [size, isMobile]);

  // Track design modifications
  useEffect(() => {
    trackStickerDesign(analytics, trackEvents.DESIGN_EDITED, {
      width: size.width,
      height: size.height,
      shape: canvasShape,
      finish,
      quantity,
    });
  }, [analytics, size, canvasShape, finish, quantity, lastModified]);

  return (
    <div className="relative w-full h-full overflow-hidden" ref={containerRef}>
      <div 
        className={cn(
          "absolute inset-0 flex items-center justify-center",
          isMobile ? "pt-4 px-2 pb-20" : "p-4" // Less padding on mobile, more space at bottom for toolbar
        )}
      >
        <div 
          className="relative rounded-lg border border-gray-200/50 shadow-sm pointer-events-auto bg-white"
          style={{ 
            width: canvasSize.width, 
            height: canvasSize.height,
            touchAction: 'none'
          }}
        >
          <div className="absolute inset-0 z-[10] pointer-events-none">
            <Ruler 
              orientation="horizontal" 
              size={size.width} 
              totalWidth={canvasSize.width}
              className={isMobile ? "text-xs" : ""} // Smaller text on mobile
            />
            <Ruler 
              orientation="vertical" 
              size={size.height}
              totalHeight={canvasSize.height}
              className={isMobile ? "text-xs" : ""} // Smaller text on mobile
            />
          </div>
          <div className="relative z-[20]">
            <FabricCanvas 
              width={canvasSize.width} 
              height={canvasSize.height} 
            />
          </div>
        </div>
      </div>
    </div>
  );
}