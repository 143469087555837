import { fabric } from 'fabric';
import { createClipPath } from '../shapes';

export function getCanvasDataURL(canvas: fabric.Canvas): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      console.log('Starting high-quality canvas export...');
      
      // Store the current state
      const objects = canvas.getObjects();
      const wasSelected = canvas.getActiveObject();
      const controlsVisibility = objects.map(obj => ({
        obj,
        hasControls: obj.hasControls,
        hasBorders: obj.hasBorders,
        selectable: obj.selectable
      }));

      // Hide all controls and selections
      objects.forEach(obj => {
        obj.hasControls = false;
        obj.hasBorders = false;
        obj.selectable = false;
      });
      canvas.discardActiveObject();
      canvas.renderAll();
      
      // Create a temporary canvas with high DPI
      const tempCanvas = document.createElement('canvas');
      const ctx = tempCanvas.getContext('2d', { 
        willReadFrequently: true,
        alpha: true 
      })!;

      // Set dimensions with higher resolution for print quality
      // Use 8x multiplier for high-quality print resolution (300+ DPI)
      const multiplier = 8;
      tempCanvas.width = canvas.width! * multiplier;
      tempCanvas.height = canvas.height! * multiplier;

      // Enable high-quality image rendering
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      // Scale context for higher resolution while maintaining quality
      ctx.scale(multiplier, multiplier);

      // Get the shape from the canvas clipPath
      const shape = canvas.clipPath?.type;
      console.log('Shape type:', shape);

      // Clear the entire canvas to transparent
      ctx.clearRect(0, 0, canvas.width!, canvas.height!);

      if (shape) {
        console.log('Applying high-quality clip path...');
        ctx.save();
        const center = {
          x: canvas.width! / 2,
          y: canvas.height! / 2
        };

        // Create the clipping path with anti-aliasing
        ctx.beginPath();
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        switch (shape.toLowerCase()) {
          case 'circle': {
            console.log('Drawing circle clip path');
            const diameter = Math.min(canvas.width!, canvas.height!);
            const radius = diameter / 2;
            ctx.arc(center.x, center.y, radius, 0, Math.PI * 2);
            break;
          }
          case 'rect':
            console.log('Drawing rectangle clip path');
            ctx.rect(0, 0, canvas.width!, canvas.height!);
            break;
          case 'triangle':
          case 'polygon': {
            // Get the original clipPath object to check its points
            const clipPath = canvas.clipPath as fabric.Polygon;
            const points = clipPath.points || [];
            
            if (points.length === 3) {
              console.log('Drawing triangle clip path');
              const base = canvas.width!;
              const height = canvas.height!;
              
              ctx.moveTo(center.x, center.y - height/2); // Top
              ctx.lineTo(center.x + base/2, center.y + height/2); // Bottom right
              ctx.lineTo(center.x - base/2, center.y + height/2); // Bottom left
            } else if (points.length === 6) {
              console.log('Drawing hexagon clip path');
              const width = canvas.width!;
              const height = canvas.height!;
              
              // Draw hexagon points
              ctx.moveTo(center.x + width/2, center.y);                    // Right
              ctx.lineTo(center.x + width/4, center.y - height/2);        // Top right
              ctx.lineTo(center.x - width/4, center.y - height/2);        // Top left
              ctx.lineTo(center.x - width/2, center.y);                   // Left
              ctx.lineTo(center.x - width/4, center.y + height/2);        // Bottom left
              ctx.lineTo(center.x + width/4, center.y + height/2);        // Bottom right
            } else {
              console.warn('Unknown polygon type, defaulting to rectangle');
              ctx.rect(0, 0, canvas.width!, canvas.height!);
            }
            ctx.closePath();
            break;
          }
          default:
            console.warn(`Unknown shape type: ${shape}, defaulting to rectangle`);
            ctx.rect(0, 0, canvas.width!, canvas.height!);
        }
        
        // Apply the clip path with high-quality anti-aliasing
        ctx.clip('evenodd');
      }

      // Draw the canvas content with high quality
      console.log('Drawing high-quality canvas content...');
      ctx.drawImage(canvas.lowerCanvasEl, 0, 0, canvas.width!, canvas.height!);

      // Create a temporary canvas to check for transparency
      const checkCanvas = document.createElement('canvas');
      checkCanvas.width = canvas.width!;
      checkCanvas.height = canvas.height!;
      const checkCtx = checkCanvas.getContext('2d', { 
        willReadFrequently: true,
        alpha: true 
      })!;
      checkCtx.drawImage(canvas.lowerCanvasEl, 0, 0);
      const imageData = checkCtx.getImageData(0, 0, canvas.width!, canvas.height!);
      const data = imageData.data;
      
      // Check if there are any fully transparent pixels
      let hasTransparency = false;
      for (let i = 3; i < data.length; i += 4) {
        if (data[i] < 254) { // Use 254 threshold to catch near-transparent pixels
          hasTransparency = true;
          break;
        }
      }

      // Only fill with background color if there's transparency
      if (hasTransparency && canvas.backgroundColor && canvas.backgroundColor !== 'transparent') {
        console.log('Applying background color to transparent areas');
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = canvas.backgroundColor;
        ctx.fillRect(0, 0, canvas.width!, canvas.height!);
        ctx.globalCompositeOperation = 'source-over';
      }

      // Get the final image data URL with maximum quality
      console.log('Generating final high-quality data URL...');
      let dataUrl: string;
      try {
        // Force PNG format with maximum quality
        dataUrl = tempCanvas.toDataURL('image/png', 1.0);
        console.log('Initial data URL format check:', {
          startsWithDataImage: dataUrl.startsWith('data:image/'),
          length: dataUrl.length,
          prefix: dataUrl.substring(0, 30) + '...'
        });

        // If the data URL is not in the correct format, try to fix it
        if (!dataUrl.startsWith('data:image/')) {
          console.warn('Data URL format incorrect, attempting to fix...');
          
          // Create a new canvas and try again with explicit settings
          const fixCanvas = document.createElement('canvas');
          const fixCtx = fixCanvas.getContext('2d', {
            willReadFrequently: true,
            alpha: true
          });
          if (!fixCtx) {
            throw new Error('Could not get 2D context for fix canvas');
          }
          
          // Copy dimensions and content with high quality
          fixCanvas.width = tempCanvas.width;
          fixCanvas.height = tempCanvas.height;
          fixCtx.imageSmoothingEnabled = true;
          fixCtx.imageSmoothingQuality = 'high';
          fixCtx.drawImage(tempCanvas, 0, 0);
          
          // Try to get data URL again with explicit MIME type and maximum quality
          dataUrl = fixCanvas.toDataURL('image/png', 1.0);
          console.log('Fixed data URL format check:', {
            startsWithDataImage: dataUrl.startsWith('data:image/'),
            length: dataUrl.length,
            prefix: dataUrl.substring(0, 30) + '...'
          });
          
          fixCanvas.remove();
        }

        // Final validation
        if (!dataUrl.startsWith('data:image/')) {
          throw new Error('Failed to generate valid image data URL');
        }
      } catch (error) {
        console.error('Error generating data URL:', error);
        throw error;
      }
      
      // Restore canvas state
      objects.forEach((obj, i) => {
        obj.hasControls = controlsVisibility[i].hasControls;
        obj.hasBorders = controlsVisibility[i].hasBorders;
        obj.selectable = controlsVisibility[i].selectable;
      });
      if (wasSelected) {
        canvas.setActiveObject(wasSelected);
      }
      canvas.renderAll();

      // Clean up
      tempCanvas.remove();
      checkCanvas.remove();

      resolve(dataUrl);
    } catch (error) {
      console.error('Error in getCanvasDataURL:', error);
      reject(error);
    }
  });
}

export function getCanvasSVG(canvas: fabric.Canvas): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const svg = canvas.toSVG();
      resolve(svg);
    } catch (error) {
      console.error('Error generating canvas SVG:', error);
      reject(error);
    }
  });
}