import React from 'react';
import { Download } from 'lucide-react';
import { Button } from './ui/button';
import { useSticker } from '../context/StickerContext';
import { getCanvasDataURL } from '../utils/canvas/exportHandlers';
import { useWindow } from '../hooks/useWindow';

export default function DownloadButton() {
  const { canvas } = useSticker();
  const { isMobile } = useWindow();

  const handleDownload = async () => {
    if (!canvas) return;

    try {
      const dataUrl = await getCanvasDataURL(canvas);
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'sticker-design.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading design:', error);
    }
  };

  return (
    <Button
      variant="outline"
      size={isMobile ? "icon" : "default"}
      onClick={handleDownload}
      className={`
        transition-all duration-200
        hover:bg-accent/10 hover:shadow-md
        border-border/50
        ${isMobile ? 'aspect-square p-2' : 'px-4 py-2'}
      `}
    >
      <Download className={`${isMobile ? 'h-5 w-5' : 'h-4 w-4 mr-2'}`} />
      {!isMobile && 'Download'}
    </Button>
  );
}