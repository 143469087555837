import { useState, useEffect } from 'react';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Minus, Plus } from 'lucide-react';

interface DimensionInputProps {
  value: number;
  onChange: (value: number) => void;
  onBlur?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  label?: string;
}

export default function DimensionInput({
  value,
  onChange,
  onBlur,
  min = 1,
  max = 12,
  step = 1,
  label,
}: DimensionInputProps) {
  const [inputValue, setInputValue] = useState(value.toString());
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused) {
      setInputValue(value.toString());
    }
  }, [value, isFocused]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setInputValue(val);
    
    const numVal = parseFloat(val);
    if (!isNaN(numVal)) {
      // Always round to whole numbers immediately
      const roundedVal = Math.round(numVal);
      // Only update if it's within bounds
      if (roundedVal >= min && roundedVal <= max) {
        onChange(roundedVal);
      }
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    let numVal = parseFloat(inputValue);
    
    if (isNaN(numVal)) {
      numVal = value;
    } else {
      // Round to nearest whole number
      numVal = Math.round(numVal);
      // Constrain to min/max
      numVal = Math.max(min, Math.min(max, numVal));
    }
    
    setInputValue(numVal.toString());
    onChange(numVal);
    onBlur?.(numVal);
  };

  const increment = () => {
    const newValue = Math.min(max, Math.round(value + step));
    setInputValue(newValue.toString());
    onChange(newValue);
    onBlur?.(newValue);
  };

  const decrement = () => {
    const newValue = Math.max(min, Math.round(value - step));
    setInputValue(newValue.toString());
    onChange(newValue);
    onBlur?.(newValue);
  };

  return (
    <div className="flex flex-col space-y-2">
      {label && (
        <span className="text-sm font-medium text-gray-700">{label}</span>
      )}
      <div className="flex items-center">
        <Button
          type="button"
          variant="outline"
          className="px-3 h-10 rounded-r-none border-r-0"
          onClick={decrement}
        >
          <Minus className="h-4 w-4" />
        </Button>
        <Input
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          className="h-10 rounded-none text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          style={{ minWidth: 0, width: '4rem' }}
          min={min}
          max={max}
          step={1}
        />
        <Button
          type="button"
          variant="outline"
          className="px-3 h-10 rounded-l-none border-l-0"
          onClick={increment}
        >
          <Plus className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
}
