interface CheckoutItem {
  price: number;
  quantity: number;
  size: string;
  finish: string;
  shape: string;
  name: string;
  image_url?: string;
  customer_email?: string;
  customer_phone?: string;
  shipping_address?: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  billing_address?: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

interface UploadResponse {
  success: boolean;
  url: string;
}

interface DesignUploadParams {
  imageData: string;
  shape: string;
  size: {
    width: number;
    height: number;
  };
  title: string;
  quantity: number;
}

export const uploadDesign = async (params: DesignUploadParams): Promise<string> => {
  const workerUrl = import.meta.env.VITE_R2_WORKER_URL;
  if (!workerUrl) {
    throw new Error('R2 worker URL not configured');
  }

  try {
    // Validate image data format
    if (!params.imageData.startsWith('data:image/')) {
      console.error('Invalid image data format:', {
        startsWithDataImage: params.imageData.startsWith('data:image/'),
        length: params.imageData.length,
        prefix: params.imageData.substring(0, 30) + '...'
      });
      throw new Error('Invalid image data format');
    }

    console.log('Uploading design to R2...', {
      shape: params.shape,
      size: params.size,
      title: params.title,
      quantity: params.quantity,
      imageDataLength: params.imageData.length
    });

    const response = await fetch(workerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        image: params.imageData,
        fileName: params.title,
        shape: params.shape,
        size: params.size,
        title: params.title,
        quantity: params.quantity
      }),
    });

    if (!response.ok) {
      let errorData;
      try {
        errorData = await response.json();
        console.error('Upload error response:', errorData);
      } catch (e) {
        errorData = await response.text();
        console.error('Upload error text:', errorData);
      }
      throw new Error(typeof errorData === 'object' ? errorData.error || 'Failed to upload design' : errorData);
    }

    const data = await response.json() as UploadResponse;
    if (!data.url) {
      throw new Error('No URL returned from upload');
    }

    console.log('Design uploaded successfully:', data.url);
    return data.url;
  } catch (error) {
    console.error('Error uploading design:', error);
    throw error;
  }
};

export const initiateCheckout = async (items: CheckoutItem[]) => {
  const workerUrl = import.meta.env.VITE_SHOPIFY_WORKER_URL;
  if (!workerUrl) {
    throw new Error('Shopify worker URL not configured');
  }

  try {
    const item = items[0];
    if (!item.image_url) {
      throw new Error('No image URL provided for checkout');
    }

    // Extract width and height from size string (e.g., "3\" × 4\"")
    const [width, height] = item.size.split('×').map(dim => parseFloat(dim.trim()));

    // Validate image URL
    if (item.image_url.startsWith('data:image/')) {
      console.error('Invalid image URL format: Got data URL instead of HTTP URL');
      throw new Error('Invalid image URL format');
    }

    if (!item.image_url.startsWith('http')) {
      console.error('Invalid image URL format: URL must start with http/https');
      throw new Error('Invalid image URL format');
    }

    console.log('Initiating Shopify checkout...', {
      name: item.name,
      size: item.size,
      shape: item.shape,
      finish: item.finish,
      quantity: item.quantity,
      price: item.price,
      imageUrl: item.image_url
    });

    const response = await fetch(workerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: [{
          ...item,
          size: `${width}" × ${height}"`,
          image_url: item.image_url,
          customer_email: item.customer_email,
          customer_phone: item.customer_phone
        }],
        origin: window.location.origin
      }),
    });

    if (!response.ok) {
      let errorData;
      try {
        errorData = await response.json();
        console.error('Checkout error response:', errorData);
      } catch (e) {
        errorData = await response.text();
        console.error('Checkout error text:', errorData);
      }
      throw new Error(typeof errorData === 'object' ? errorData.error || 'Failed to start checkout' : errorData);
    }

    const data = await response.json();
    if (!data.invoice_url) {
      console.error('Missing invoice_url in response:', data);
      throw new Error('No checkout URL returned');
    }

    console.log('Redirecting to checkout:', data.invoice_url);
    window.location.href = data.invoice_url;
  } catch (error) {
    console.error('Error during checkout:', error);
    throw error;
  }
};