import { Analytics } from '@segment/analytics-next';

const ANONYMOUS_ID_KEY = 'sticker_app_anonymous_id';

// Get or create anonymous ID
export const getAnonymousId = () => {
  let anonymousId = localStorage.getItem(ANONYMOUS_ID_KEY);
  if (!anonymousId) {
    anonymousId = crypto.randomUUID();
    localStorage.setItem(ANONYMOUS_ID_KEY, anonymousId);
  }
  return anonymousId;
};

export interface StickerDesignTrackingProps {
  width: number;
  height: number;
  shape: string;
  finish: 'matte' | 'glossy';
  quantity: number;
  price: number;
}

export interface UserIdentifyProps {
  userId?: string;
  traits?: {
    email?: string;
    name?: string;
    [key: string]: any;
  };
}

export const trackEvents = {
  DESIGN_CREATED: 'Design Created',
  DESIGN_EDITED: 'Design Edited',
  DESIGN_NAME_CHANGED: 'Design Name Changed',
  SHAPE_CHANGED: 'Shape Changed',
  SIZE_CHANGED: 'Size Changed',
  FINISH_CHANGED: 'Finish Changed',
  QUANTITY_CHANGED: 'Quantity Changed',
  CHECKOUT_STARTED: 'Checkout Started',
  CHECKOUT_COMPLETED: 'Checkout Completed',
  DESIGN_DOWNLOADED: 'Design Downloaded',
} as const;

interface BaseTrackingProps {
  width?: number;
  height?: number;
  shape?: string;
  finish?: string;
  quantity?: number;
  price?: number;
  [key: string]: any;
}

export const trackStickerDesign = async (
  analytics: Analytics | null,
  event: string,
  properties: BaseTrackingProps
) => {
  if (!analytics) {
    console.warn('Analytics instance not initialized');
    return;
  }

  try {
    const anonymousId = getAnonymousId();
    const baseProperties = {
      timestamp: new Date().toISOString(),
      anonymousId,
      ...properties
    };

    await analytics.track(event, baseProperties);
  } catch (error) {
    console.error('Failed to track event:', event, error);
  }
};

export const identifyUser = async (
  analytics: Analytics | null,
  { userId, traits }: UserIdentifyProps
) => {
  if (!analytics) {
    console.warn('Analytics instance not initialized');
    return;
  }

  if (!userId) {
    console.warn('User ID is required for identification');
    return;
  }

  try {
    const anonymousId = getAnonymousId();
    await analytics.identify(userId, {
      anonymousId,
      ...traits,
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error('Failed to identify user:', userId, error);
  }
};
