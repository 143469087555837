import { useCallback, useRef, useState, useEffect } from 'react';
import { fabric } from 'fabric';
import { colord } from 'colord';

export function useEyeDropper(canvas: fabric.Canvas | null) {
  const [isActive, setIsActive] = useState(false);
  const originalCursor = useRef<string>('');

  const setCursorStyle = useCallback((cursor: string) => {
    if (!canvas) return;
    canvas.defaultCursor = cursor;
    canvas.forEachObject((obj) => {
      obj.hoverCursor = cursor;
    });
  }, [canvas]);

  const setObjectsSelectable = useCallback((selectable: boolean) => {
    if (!canvas) return;
    canvas.selection = selectable;
    canvas.forEachObject((obj) => {
      obj.selectable = selectable;
    });
  }, [canvas]);

  const startEyeDropper = useCallback(() => {
    if (!canvas) return;

    setIsActive(true);
    originalCursor.current = canvas.defaultCursor || '';
    setCursorStyle('crosshair');
    setObjectsSelectable(false);
  }, [canvas, setCursorStyle, setObjectsSelectable]);

  const stopEyeDropper = useCallback(() => {
    if (!canvas) return;

    setIsActive(false);
    setCursorStyle(originalCursor.current);
    setObjectsSelectable(true);
  }, [canvas, setCursorStyle, setObjectsSelectable]);

  const getColorFromPoint = useCallback((event: fabric.IEvent): string | null => {
    if (!canvas || !event.e) return null;

    const pointer = canvas.getPointer(event.e);
    const context = canvas.getContext();

    // Get pixel data at pointer position
    const imageData = context.getImageData(
      Math.round(pointer.x),
      Math.round(pointer.y),
      1,
      1
    );

    if (!imageData) return null;

    return colord({
      r: imageData.data[0],
      g: imageData.data[1],
      b: imageData.data[2],
      a: imageData.data[3] / 255
    }).toHex();
  }, [canvas]);

  const handleEyeDropper = useCallback((callback: (color: string) => void) => {
    if (!canvas) return;

    const handleMouseMove = (event: fabric.IEvent) => {
      if (!isActive) return;
      const color = getColorFromPoint(event);
      if (color) {
        // Update preview or tooltip here if needed
      }
    };

    const handleMouseDown = (event: fabric.IEvent) => {
      if (!isActive) return;
      const color = getColorFromPoint(event);
      if (color) {
        callback(color);
        stopEyeDropper();
      }
    };

    canvas.on('mouse:move', handleMouseMove);
    canvas.on('mouse:down', handleMouseDown);

    return () => {
      canvas.off('mouse:move', handleMouseMove);
      canvas.off('mouse:down', handleMouseDown);
      if (isActive) {
        stopEyeDropper();
      }
    };
  }, [canvas, isActive, getColorFromPoint, stopEyeDropper]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (isActive) {
        stopEyeDropper();
      }
    };
  }, [isActive, stopEyeDropper]);

  return {
    isActive,
    startEyeDropper,
    stopEyeDropper,
    handleEyeDropper
  };
}
