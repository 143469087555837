import React, { useState } from 'react';
import { ShoppingCart, Menu, X } from 'lucide-react';
import CheckoutModal from './CheckoutModal';
import DownloadButton from './DownloadButton';
import EditableTitle from './EditableTitle';
import { Button } from './ui/button';
import { useWindow } from '../hooks/useWindow';
import { useSticker } from '../context/StickerContext';

interface NavbarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (open: boolean) => void;
  setIsModalOpen: (open: boolean) => void;
}

const CarrcoLabelsLogo = () => (
  <a 
    href="https://carrcolabels.com" 
    target="_blank" 
    rel="noopener noreferrer"
    className="flex items-center"
  >
    <img 
      src="https://pub-d49ad981336d46b489310e32617d4460.r2.dev/CARRCO-labels-logo-CROPPED.png"
      alt="Carrco Labels" 
      className="h-8"
    />
  </a>
);

export default function Navbar({ 
  isSidebarOpen, 
  setIsSidebarOpen, 
  setIsModalOpen 
}: NavbarProps) {
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const { isMobile } = useWindow();
  const { designName } = useSticker();

  const handleCheckout = () => {
    setIsCheckoutOpen(true);
    setIsModalOpen(true);
  };

  const handleCloseCheckout = () => {
    setIsCheckoutOpen(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 bg-background/80 backdrop-blur-md border-b border-border/40 flex h-14 supports-[backdrop-filter]:bg-background/60">
        <div className="flex-1 max-w-[1920px] mx-auto px-4">
          <div className="flex justify-between items-center h-full relative">
            <div className="flex items-center space-x-4 z-10">
              {isMobile && (
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  className={`
                    transition-all duration-200 ease-in-out
                    ${isSidebarOpen ? 'bg-accent hover:bg-accent/90' : 'hover:bg-accent/10'}
                    shadow-sm hover:shadow-md
                  `}
                >
                  {isSidebarOpen ? 
                    <X className="h-5 w-5 text-accent-foreground" /> : 
                    <Menu className="h-5 w-5" />
                  }
                </Button>
              )}
              <CarrcoLabelsLogo />
            </div>
            
            {/* Only show title on desktop */}
            {!isMobile && (
              <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex items-center">
                <EditableTitle />
              </div>
            )}

            <div className="flex items-center space-x-2">
              <DownloadButton />
              <Button
                variant="default"
                size="default"
                onClick={handleCheckout}
                className={`
                  shadow-lg shadow-primary/20 hover:shadow-xl hover:shadow-primary/30 
                  transition-all duration-200
                  ${isSidebarOpen ? 'bg-primary/90 hover:bg-primary' : ''}
                `}
              >
                <ShoppingCart className="mr-2 h-4 w-4" />
                {isMobile ? '' : 'Checkout'}
              </Button>
            </div>
          </div>
        </div>
      </nav>

      <CheckoutModal 
        isOpen={isCheckoutOpen}
        onClose={handleCloseCheckout}
      />
    </>
  );
}