import React, { createContext, useContext } from 'react';

// Mock Analytics type to match the shape of Segment Analytics
interface MockAnalytics {
  track: (args: any) => void;
  page: (args: any) => void;
  identify: (args: any) => void;
  setAnonymousId: (id: string) => void;
}

interface AnalyticsContextType {
  analytics: MockAnalytics | null;
}

// Create a mock analytics object that does nothing
const mockAnalytics: MockAnalytics = {
  track: () => {},
  page: () => {},
  identify: () => {},
  setAnonymousId: () => {},
};

const AnalyticsContext = createContext<AnalyticsContextType>({ analytics: mockAnalytics });

export const useAnalytics = () => useContext(AnalyticsContext);

interface AnalyticsProviderProps {
  children: React.ReactNode;
  writeKey: string;
}

export function AnalyticsProvider({ children, writeKey }: AnalyticsProviderProps) {
  // Always provide the mock analytics object
  return (
    <AnalyticsContext.Provider value={{ analytics: mockAnalytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
}
