import { useCallback } from 'react';
import { fabric } from 'fabric';

interface CanvasState {
  version: string;
  objects: any[];
  background: string;
}

export const useCanvasState = () => {
  const saveState = useCallback((canvas: fabric.Canvas): CanvasState => {
    return {
      version: '1.0',
      objects: canvas.getObjects().map(obj => ({
        ...obj.toJSON(['id', 'selectable', 'crossOrigin']),
        type: obj.type,
        src: obj.type === 'image' ? (obj as fabric.Image).getSrc() : undefined
      })),
      background: canvas.backgroundColor as string
    };
  }, []);

  const restoreState = useCallback(async (
    canvas: fabric.Canvas,
    state: CanvasState,
    width: number,
    height: number
  ): Promise<void> => {
    if (!state || !state.objects) {
      console.warn('Invalid state provided to restoreState');
      return;
    }

    // Clear existing objects
    canvas.clear();

    // Set canvas dimensions
    canvas.setWidth(width);
    canvas.setHeight(height);

    // Set background color
    if (state.background) {
      canvas.setBackgroundColor(state.background, canvas.renderAll.bind(canvas));
    }

    // Restore objects
    try {
      const objects = await Promise.all(
        state.objects.map(async (objData) => {
          return new Promise((resolve, reject) => {
            // Handle different object types
            if (objData.type === 'image' && objData.src) {
              fabric.Image.fromURL(
                objData.src,
                (img) => {
                  // Apply saved properties
                  img.set(objData);
                  resolve(img);
                },
                {
                  crossOrigin: 'anonymous'
                }
              );
            } else {
              // For non-image objects or images without src
              try {
                const klass = fabric[objData.type as keyof typeof fabric];
                if (typeof klass === 'function') {
                  const instance = new (klass as any)(objData);
                  resolve(instance);
                } else {
                  console.warn(`Unknown object type: ${objData.type}`);
                  resolve(null);
                }
              } catch (error) {
                console.warn(`Error creating object of type ${objData.type}:`, error);
                resolve(null);
              }
            }
          });
        })
      );

      // Add valid objects to canvas
      objects.filter(Boolean).forEach(obj => {
        if (obj) {
          canvas.add(obj);
        }
      });

      // Render the canvas
      canvas.renderAll();
    } catch (error) {
      console.error('Error restoring canvas state:', error);
    }
  }, []);

  return { saveState, restoreState };
};
