import React, { useEffect, useCallback } from 'react';
import { RgbaColorPicker } from 'react-colorful';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { useColorPicker, RGBAColor } from '../hooks/useColorPicker';
import { useSticker } from '../context/StickerContext';
import { Pipette } from 'lucide-react';
import { Button } from './ui/button';
import { useEyeDropper } from '../hooks/useEyeDropper';
import { useWindow } from '../hooks/useWindow';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
}

const ColorPickerTabs = {
  picker: 'picker',
  rgb: 'rgb',
  cmyk: 'cmyk'
} as const;

export default function ColorPicker({ color, onChange }: ColorPickerProps) {
  const { canvas } = useSticker();
  const { isMobile } = useWindow();
  const {
    color: rgbaColor,
    cmykValues,
    handleColorChange,
    handleCMYKChange,
    getRgbaString
  } = useColorPicker(color);

  const { isActive, startEyeDropper, stopEyeDropper, handleEyeDropper } = useEyeDropper(canvas);

  const handleRGBAChange = useCallback((newColor: RGBAColor) => {
    const hexColor = handleColorChange(newColor);
    onChange(hexColor);
  }, [handleColorChange, onChange]);

  const handleCMYKUpdate = useCallback((key: keyof typeof cmykValues, value: number) => {
    const hexColor = handleCMYKChange(key, value);
    onChange(hexColor);
  }, [handleCMYKChange, onChange]);

  // Set up eyedropper event handlers
  useEffect(() => {
    if (isActive) {
      return handleEyeDropper((pickedColor) => {
        onChange(pickedColor);
      });
    }
  }, [isActive, handleEyeDropper, onChange]);

  const renderRGBInputs = useCallback(() => (
    <div className="grid grid-cols-2 gap-4">
      {(['r', 'g', 'b'] as const).map((channel) => (
        <div key={channel}>
          <Label>{channel.toUpperCase()}</Label>
          <Input
            type="number"
            min={0}
            max={255}
            value={rgbaColor[channel]}
            onChange={(e) => {
              const newColor = { ...rgbaColor, [channel]: Number(e.target.value) };
              handleRGBAChange(newColor);
            }}
          />
        </div>
      ))}
      <div>
        <Label>Alpha</Label>
        <Input
          type="number"
          min={0}
          max={1}
          step="0.01"
          value={rgbaColor.a}
          onChange={(e) => {
            const newColor = { ...rgbaColor, a: Number(e.target.value) };
            handleRGBAChange(newColor);
          }}
        />
      </div>
    </div>
  ), [rgbaColor, handleRGBAChange]);

  const renderCMYKInputs = useCallback(() => (
    <div className="grid grid-cols-2 gap-4">
      {(Object.keys(cmykValues) as Array<keyof typeof cmykValues>).map((key) => (
        <div key={key}>
          <Label>{key.toUpperCase()}</Label>
          <Input
            type="number"
            min={0}
            max={100}
            value={cmykValues[key]}
            onChange={(e) => handleCMYKUpdate(key, Number(e.target.value))}
          />
        </div>
      ))}
    </div>
  ), [cmykValues, handleCMYKUpdate]);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <Label>Background Color</Label>
        {!isMobile && (
          <Button
            variant="outline"
            size="sm"
            className={isActive ? 'bg-blue-50 border-blue-500' : ''}
            onClick={() => isActive ? stopEyeDropper() : startEyeDropper()}
          >
            <Pipette className="w-4 h-4 mr-2" />
            {isActive ? 'Cancel' : 'Pick Color'}
          </Button>
        )}
      </div>
      
      <Tabs defaultValue={ColorPickerTabs.picker} className="w-full">
        <TabsList className="w-full grid grid-cols-3">
          {Object.entries(ColorPickerTabs).map(([key, value]) => (
            <TabsTrigger key={key} value={value}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </TabsTrigger>
          ))}
        </TabsList>

        <TabsContent value={ColorPickerTabs.picker} className="flex justify-center pt-4">
          <RgbaColorPicker color={rgbaColor} onChange={handleRGBAChange} />
        </TabsContent>

        <TabsContent value={ColorPickerTabs.rgb} className="space-y-4 pt-4">
          {renderRGBInputs()}
        </TabsContent>

        <TabsContent value={ColorPickerTabs.cmyk} className="space-y-4 pt-4">
          {renderCMYKInputs()}
        </TabsContent>
      </Tabs>

      <div 
        className="w-full h-12 rounded-md border"
        style={{ backgroundColor: getRgbaString() }}
      />
    </div>
  );
}